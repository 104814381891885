import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"page-container pa-0",class:{ preview: _vm.preview },attrs:{"fluid":""}},[_c(VAppBar,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],staticClass:"topbar menu",class:{
      'topbar--visible': _vm.showTopBarColor,
      'elevation-2': _vm.showTopBarColor,
    },attrs:{"fixed":!_vm.preview,"flat":"","dark":!_vm.preview,"color":"transparent"}},[_c(VBtn,{attrs:{"plain":""},on:{"click":function($event){return _vm.$vuetify.goTo(0, { duration: 1000, offset: 15 })}}},[_c('img',{staticClass:"btn-logo-img",attrs:{"alt":"Brasão de Pernambuco","src":"/img/logo-SEE-branca-1-2.png"}})]),_c(VAppBarTitle,{staticClass:"d-sm-none font-weight-bold"},[_vm._v(_vm._s(_vm.app.alias))]),_c(VSpacer),_vm._l((_vm.content.sections),function(section){return [_vm._l((section.itensDeMenu),function(subLink,i){return [(
            subLink.Ativo ||
            (subLink.ativo && subLink.itensDoSubMenu && (!subLink.itensDoSubMenu.length || !subLink.itensDoSubMenu.filter(function (link) { return link.ativo; }).length))
          )?_c(VBtn,{key:i,attrs:{"plain":""},on:{"click":function($event){return _vm.handleLink(subLink)}}},[_vm._v(" "+_vm._s(subLink.nomeExibicao || subLink.menuTitle)+" ")]):_c(VMenu,{key:i,attrs:{"open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(subLink.Ativo || subLink.ativo)?_c(VBtn,_vm._g(_vm._b({attrs:{"preview":_vm.preview,"plain":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(subLink.nomeExibicao || subLink.menuTitle)+" ")]):_vm._e()]}}],null,true)},[_c(VList,{staticClass:"mt-10",class:{
            'topbar--visible': _vm.showTopBarColor,
            'elevation-2': _vm.showTopBarColor,
          },attrs:{"dark":!_vm.preview,"color":"transparent"}},[_vm._l((subLink.itensDoSubMenu.filter(function (link) { return link.ativo; })),function(link,j){return [_c(VListItem,{key:j,attrs:{"dark":!_vm.preview}},[(link.Ativo || link.ativo)?_c(VListItemContent,{attrs:{"dark":!_vm.preview}},[(link.ativo)?_c(VBtn,{attrs:{"dark":!_vm.preview,"plain":""},on:{"click":function($event){return _vm.handleLink(link)}}},[_vm._v(_vm._s(link.nomeExibicao || link.menuTitle))]):_vm._e()],1):_vm._e()],1)]})],2)],1)]})]}),_c(VSpacer)],2),_vm._l((_vm.content.sections),function(section){return [(_vm.sectionComponent(section))?_c(_vm.sectionComponent(section),{key:section.IndicadorSecao,tag:"component",attrs:{"id":section.IndicadorSecao,"preview":_vm.preview,"section":section}}):_vm._e()]}),_c('lp-social',{attrs:{"preview":_vm.preview}}),_c('lp-footer',{attrs:{"preview":_vm.preview}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }