<template>
  <footer class="white--text pt-8 pb-16">
      <v-container class="pb-16">
        <v-row>
          <v-col>
            <h4>Governo</h4>
            <hr class="mt-1 mb-5 info" />
            <ul>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/governador">Governador</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/acoes-governo">Mapa do Trabalho</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/prefeituras">Prefeituras</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/galeria-de-governadores">Galeria de Governadores</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/telefones-uteis">Telefones úteis</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/licitacoes">Licitações</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/fale-conosco/21-contato">Contato</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="http://www.educacao.pe.gov.br/portal/?men=142">Ouvidoria</a></li>
            </ul>
          </v-col>
          <v-col>
            <h4>Pernambuco</h4>
            <hr class="mt-1 mb-5 green" />
            <ul>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/historia">História</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/geografia">Geografia</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/populacao">População</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/turismo">Turismo</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/cultura">Cultura</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/simbolos">Símbolos</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/constituicao">Constituição</a></li>
            </ul>
          </v-col>
          <v-col>
            <h4>Imprensa</h4>
            <hr class="mt-1 mb-5 yellow" />
            <ul>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/blog">Blog de Notícias</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/agenda-do-governador">Agenda do Governador</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/radio-sei">Rádio SEI</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/banco-de-pautas">Banco de Pautas</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/downloads">Downloads</a></li>
            </ul>
          </v-col>
          <v-col>
            <h4>Secretarias</h4>
            <hr class="mt-1 mb-5 red" />
            <ul>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/secretarias">Secretarias</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/orgaos">Órgãos</a></li>
              <li><a @click="go" rel="noreferrer noopener" class="text-decoration-none" target="_blank" href="https://www.pe.gov.br/portal-governo-pe/todos-os-servicos">Serviços</a></li>
            </ul>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-center">
              <img
                alt="Logotipo do governo do estado de Pernambuco"
                class="footer-logo mt-16"
                src="/img/logo-SEE-branca-1-2.png"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
  </footer>
</template>

<script>
export default {
  name: "LandingPageFooter",
  props: {
    preview: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    go(event) {
      if (this.preview) {
        event.preventDefault();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: #172e51;

  h4 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      transition: 0.3s padding;

      a {
        color: #fff;
        font-weight: 300;
        font-size: 14px;
      }

      &::before {
        font: normal normal normal 24px/1 "Material Design Icons", sans-serif;
        content: "\F0142";
      }

      &:hover {
        padding-left: 5px;
      }
    }
  }
  .footer-logo {
    max-width: 400px;
    width: 100%;
  }
}
</style>