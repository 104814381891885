import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-10",attrs:{"id":_vm.section.IndicadorSecao || _vm.section.endereco || 'desgraça'}},[_c(VContainer,[_c(VRow,{staticClass:"mb-5",attrs:{"no-gutters":""}},[_c('vue-aos',{attrs:{"animation-class":"animate__zoomInLeft animate__animated"}},[_c(VCol,{staticClass:"text-center"},[_c('h2',{staticClass:"text-h4",domProps:{"textContent":_vm._s(_vm.section.title)}})])],1)],1),_c(VRow,[_c(VCol,[_c('div',{staticClass:"faq"},[_c(VTextField,{attrs:{"solo":"","rounded":"","clearable":"","loading":_vm.isSearching,"placeholder":"Faça uma pergunta","prepend-inner-icon":"mdi-magnify","autocomplete":"off"},on:{"click:clear":_vm.clear,"input":_vm.search},model:{value:(_vm.textToSearch),callback:function ($$v) {_vm.textToSearch=$$v},expression:"textToSearch"}}),_c(VExpansionPanels,{attrs:{"focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.visiblePage),function(item,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,[_c('strong',{staticClass:"text--darken-2 grey--text",domProps:{"textContent":_vm._s(item.Titulo || item.title)}})]),_c(VExpansionPanelContent,[_c('div',{staticClass:"mt-3",domProps:{"innerHTML":_vm._s(item.description)}})])],1)}),1),(_vm.searchedItems.length && _vm.paginationLength > 1)?_c('div',{staticClass:"text-center mt-5"},[_c(VPagination,{attrs:{"length":_vm.paginationLength,"circle":""},on:{"input":function($event){_vm.panel = []}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e(),(_vm.textToSearch && !_vm.isSearching && !_vm.searchedItems.length)?_c('div',{staticClass:"text-center text--darken-1 grey--text font-weight-light"},[_vm._v(" Infelizmente não encontramos nenhuma resposta... "),_c(VIcon,[_vm._v("mdi-emoticon-sad-outline")])],1):_vm._e()],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }