<template>
  <section class="landing-page-cover" :id="section.IndicadorSecao">
    <v-parallax
      :src="section.coverSrc"
      :height="parallaxHeight"
      :class="{ dark: section.dark, 'default-height': !hasCustomHeight }"
    >
      <v-row no-gutters align="center" justify="center">
        <v-col class="text-center" cols="12">
          <v-img
            v-if="section.logoSrc"
            class="mb-10"
            contain
            max-height="256"
            :src="section.logoSrc"
            alt="Logotipo do Programa investe escola pernambuco"
          ></v-img>

          <h1
            v-if="section.titulo || section.Titulo || section.title"
            class="display-2 font-weight-black mb-12"
            v-text="section.titulo ||section.Titulo || section.title"
          ></h1>

          <div
            v-if="section.secondText"
            class="subheading font-weight-light mb-3"
            v-text="section.secondText"
          ></div>

          <div
            v-if="section.thirdText"
            class="display-1 font-weight-thin mb-12"
            v-text="section.thirdText"
          ></div>

          <v-btn
            v-if="section.loginButton.title"
            class="pa-6 font-weight-regular"
            rounded
            color="success"
            :to="{ path: preview ? '' : section.loginButton.path }"
            >{{ section.loginButton.title }}</v-btn
          >
        </v-col>
      </v-row>
    </v-parallax>
  </section>
</template>

<script>
export function CoverLoginButton(options = { title: "Quero Acessar", path: "/app" }) {
  this.title = options.titulo || options.title;
  this.path = options.endereco || options.path;

  this.isValid = function () {
    return this.title ? typeof this.path === "string" && this.path.length > 0 : true;
  };

  this.stringify = function () {
    return JSON.stringify({ ...this });
  };

  this.toApi = function () {
    return {
      titulo: this.title,
      endereco: this.path,
    };
  };

  if (!this.isValid()) {
    throw new Error("Botão de Login da seção de Capa inválido.");
  }
}

export function Cover(
  options = {
    coverSrc: "/img/bg-08.jpg",
    title: "Título",
    secondText: `Texto`,
    thirdText: `Texto`,
    logoSrc: undefined,
    menuTitle: undefined,
    dark: true,
    height: undefined,
  }
) {
  Object.defineProperty(this, "type", { get: () => "cover" });
  this.height = options.altura || options.height;
  this.dark = typeof options.escuro !== "undefined" ? options.escuro : options.dark;
  this.menuTitle = options.menu || options.menuTitle;
  this.coverSrc = options.enderecoDaImagem || options.coverSrc;
  this.logoSrc = options.enderecoDaImagemDoLogotipo || options.logoSrc;
  this.title =   options.Titulo || options.titulo || options.title || options.menuTitle ;
  this.secondText = options.segundoTexto || options.secondText;
  this.thirdText = options.terceiroTexto || options.thirdText;
  this.loginButton =
    options.acesso || options.loginButton
      ? new CoverLoginButton(options.acesso || options.loginButton)
      : new CoverLoginButton();
  this.IndicadorSecao = options.IndicadorSecao || options.indicadorSecao;
  this.endereco = options.IndicadorSecao || options.endereco;

  this.isValid = function () {
    return this.coverSrc && this.loginButton.isValid();
  };

  this.stringify = function () {
    return JSON.stringify({ ...this, tipo: "cover" });
  };

  this.toApi = function () {
    return {
      altura: this.height,
      escuro: this.dark,
      menu: this.menuTitle,
      enderecoDaImagem: this.coverSrc,
      enderecoDaImagemDoLogotipo: this.logoSrc,
      titulo: this.title,
      segundoTexto: this.secondText,
      terceiroTexto: this.thirdText,
      acesso: this.loginButton.toApi(),
      Tipo: "capa",
      IndicadorSecao: this.IndicadorSecao,
      endereco: this.endereco,
      nomeExibicao: this.title,
    };
  };

  if (!this.isValid()) {
    throw new Error("Seção de Capa inválida.");
  }
}

export default {
  name: "LandingPageCover",
  props: {
    preview: {
      type: Boolean,
      required: false,
    },
    section: {
      type: Cover,
      required: true,
      validator(value) {
        return value.isValid();
      },
    },
  },
  data() {
    return {
      windowHeight: undefined,
    };
  },
  mounted() {
    console.log(this.section);
    if (isNaN(parseInt(this.section.height))) {
      window.addEventListener("resize", this.onResizeWindow);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.aoRedimensionarJanelav);
  },
  computed: {
    parallaxHeight() {
      return this.hasCustomHeight
        ? this.section.height
        : this.windowHeight || window.innerHeight;
    },
    hasCustomHeight() {
      return !isNaN(parseInt(this.section.height)) && parseInt(this.section.height) !== 0;
    },
  },
  methods: {
    onResizeWindow() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-parallax {
  max-width: 100vw;

  &.default-height {
    min-height: calc(100vh + 12px);
  }

  &.dark {
    .v-parallax__image-container {
      filter: brightness(50%);
    }
  }
}
</style>
