<template>
  <section :id="section.IndicadorSecao" class="my-12">
    <v-container>
      <h1 class="title">CONSULTA DE CREDENCIAMENTO</h1>

      <v-form v-model="form">
        <v-row class="d-flex justify-center mt-3">
          <v-col cols="2">
            <v-text-field
              v-model="nomeUnidadeEnsino"
              label="Nome da Unidade de Ensino"
              :rules="[minLengthRule(4)]"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="listaMunicipio"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="municipio"
              label="Município"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="listaUnidadeRegional"
              :item-text="'nome'"
              :item-value="'id'"
              v-model="unidadeRegional"
              label="Unidade Regional"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="cadastroEscolar"
              label="Cadastro Escolar"
              :rules="[minLengthRule(4)]"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="cnpj"
              label="CNPJ"
              :rules="[minLengthRule(4)]"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!permitirConsultar"
              :color="permitirConsultar ? 'success' : 'secondary'"
              @click="consultarInformacoes()">
              CONSULTAR
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-data-table
        v-if="exibirResultadoConsulta"
        class="mt-5"
        :headers="headers"
        :items="tableData"
        :footer-props="$footerProps"
        style="cursor: pointer"
        @click:row="exibirInformacoes">
        <template v-slot:[`item.unidadeRegional.nome`]="{ item }">
          {{ item.unidadeRegional.nome }}
        </template>
        <template v-slot:[`item.nomeUnidadeEnsino`]="{ item }">
          {{ item.nomeUnidadeEnsino }}
        </template>
        <template v-slot:[`item.situacaoFuncionamento.nome`]="{ item }">
          {{ item.situacaoFuncionamento.nome == 'Em Atividade' ? 'Credenciada' : 'Não Localizada' }}
        </template>
        <template slot="no-data">
          Nenhuma unidade de ensino encontrada.
        </template>
     </v-data-table>

      <v-dialog v-model="exibirInformacoesAdicionais">
        <v-container>
          <v-card class="m-10 p-10">
            <v-toolbar dark color="primary">
                <v-card-text class="text-left"><v-icon >mdi-eye</v-icon> VISUALIZAR DADOS DA UNIDADE DE ENSINO
                </v-card-text>
                <v-card-text class="text-right">
                  <v-icon @click="esconderInformacoes()">mdi-close</v-icon>
                </v-card-text>
            </v-toolbar>            
            <div class="container dialog mt-8">
              <section>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="informacoesAdicionais.situacaoFuncionamento.nome"
                      label="SITUAÇÃO"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </section>
              <section>
                <v-card-title>IDENTIFICAÇÃO</v-card-title>
                <v-row>
                  <v-col cols="2">
                    <v-text-field
                      v-model="informacoesAdicionais.codigoMec"
                      label="Código MEC"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="informacoesAdicionais.codigo"
                      label="Cadastro Escolar"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="5">
                    <v-text-field
                      v-model="informacoesAdicionais.nomeUnidadeEnsino"
                      label="Unidade de Ensino"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="informacoesAdicionais.cnpj"
                      label="CNPJ"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="informacoesAdicionais.nomeEmpresarial"
                      label="Nome Empresarial"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="informacoesAdicionais.dependenciaAdministrativa.nome"
                      label="Dependência Administrativa"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="informacoesAdicionais.situacaoFuncionamento.nome"
                      label="Situação Funcionamento"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="informacoesAdicionais.unidadeRegional.nome"
                      label="Unidade Regional"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="informacoesAdicionais.listaIdentificacoes"
                      label="Identificação"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </section>
              <section>
                <v-card-title>ENDEREÇO</v-card-title>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="informacoesAdicionais.logradouro"
                      label="Logradouro"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="informacoesAdicionais.numero"
                      label="Número"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="informacoesAdicionais.cep"
                      label="CEP"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="informacoesAdicionais.bairro"
                      label="Bairro"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="informacoesAdicionais.complemento"
                      label="Complemento"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="informacoesAdicionais.municipio.nome"
                      label="Município"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="informacoesAdicionais.uf"
                      label="UF"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="informacoesAdicionais.localizacao.nome"
                      label="Localização"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </section>
              <section>
                <v-card-title>PORTARIAS</v-card-title>
                <v-data-table
                  :headers="headersPortaria"
                  :items="informacoesAdicionais.portarias"
                  :footer-props="$footerProps"
                ></v-data-table>
              </section>
            </div>
          </v-card>
        </v-container>
      </v-dialog>
    </v-container>
  </section>
</template>

<script>
  import pageLoadingMixin from "mixins/page-loading";
  import { Municipio, GerenciaRegional, UnidadeDeEnsino } from "~/commons/classes.js";

  export class ConsultarCredenciamento {
    constructor(options) {
      this.menuTitle = options?.menu || options?.menuTitle;
      this.title = options?.Titulo || options?.titulo || options?.title || options?.menuTitle;
      this.endereco = options?.IndicadorSecao || options?.endereco;
      this.IndicadorSecao = options?.IndicadorSecao || options?.IndicadorSecao || options?.endereco;

      Object.defineProperty(this, "type", { get: () => "consultarCredenciamento" });

      this.isValid = function () {
        return !!this.title;
      };

      this.stringify = function () {
        return JSON.stringify({ ...this, tipo: "consultarCredenciamento" });
      };

      this.toApi = function () {
        return {
          Tipo: "consultarCredenciamento",
          IndicadorSecao: this.IndicadorSecao,
        };
      };
    }
  }

  export default {
    name: "LandingPageConsultarCredenciamento",
    mixins: [pageLoadingMixin],
    props: {
      preview: {
        type: Boolean,
        required: false,
      },
      section: {
        type: ConsultarCredenciamento,
        required: true,
      },
    },
    data() {
      return {
        form: false,
        exibirInformacoesAdicionais: false,
        informacoesAdicionais: UnidadeDeEnsino.Build(),
        exibirResultadoConsulta: false,
        nomeUnidadeEnsino: "",
        municipio: 0,
        unidadeRegional: 0,
        cadastroEscolar: "",
        cnpj: "",
        listaMunicipio: [],
        listaUnidadeRegional: [],
        headers: [
          { text: "UNIDADE REGIONAL", align: "start", value: "unidadeRegional.nome" },
          { text: "ESCOLA", align: "start", value: "nomeUnidadeEnsino" },
          { text: "SITUAÇÃO", align: "center", value: "situacaoFuncionamento.nome"},
        ],
        tableData: [],
        headersPortaria: [
          { text: "Nº Portaria", align: "center", value: "numeroPortaria" },
          { text: "Dado do DO", align: "center", value: "dataDo" },
          { text: "Finalidade", align: "center", value: "listaFinalidades" },
          { text: "Oferta de Ensino", align: "center", value: "listaOfertasEnsino" }
        ]
      };
    },

    mounted() {
      this.carregarMunicipios();
      this.carregarGerenciasRegionais();
    },

    methods: {
      carregarMunicipios() {
        this.$obterMunicipio().then(({ data }) => {
          this.listaMunicipio = data.lista.map((municipio) => new Municipio(municipio));
        });
      },

      carregarGerenciasRegionais() {
        this.$obterUnidadeRegional().then(({ data }) => {
          this.listaUnidadeRegional = data.lista.map((unidade) => new GerenciaRegional(unidade));
        });
      },

      consultarInformacoes() {
        const params = {
          params: {
            nomeUnidadeEnsino: this.nomeUnidadeEnsino,
            municipioId: this.municipio,
            unidRegionalId: this.unidadeRegional,
            cadastroEscolar: this.cadastroEscolar,
            cnpj: this.cnpj,
            ativo: true
          }};
          
        this.showPageLoading();
        this.$consultarCredenciamentoUnidadeEnsino(params).then(({ data }) => {
          this.tableData = data.lista;
        })
        .finally(() => { 
          this.hidePageLoading();
        });

        this.exibirResultadoConsulta = true;
      },

      exibirInformacoes(item) {
        const params = { params: { id: item.id } };

        this.showPageLoading();
        this.$obterCredenciamentoUnidadeEnsino(params).then(({ data }) => {
          this.informacoesAdicionais = new UnidadeDeEnsino(data.unidadeEnsino);

          this.informacoesAdicionais.cnpj = this.$formatCnpj(this.informacoesAdicionais.cnpj);

          this.informacoesAdicionais.listaIdentificacoes = this.obterListaNomes(this.informacoesAdicionais.identificacoes);
          this.informacoesAdicionais.portarias.map(p => {
            p.dataDo = this.$formatarData(p.dataDo);
            p.listaFinalidades = this.obterListaNomes(p.nomesFinalidades);
            p.listaOfertasEnsino = this.obterListaNomes(p.nomesOfertasEnsino);
          });

          this.exibirInformacoesAdicionais = true;
        })
        .finally(() => { 
          this.hidePageLoading();
        });
      },

      esconderInformacoes() {
        this.exibirInformacoesAdicionais = false;
      },

      obterListaNomes(lista) {
        if (!lista || lista.length == 0) {
          return "";
        }

        return lista.map(i => i.nome ? i.nome : i).join(', ');
      },

      minLengthRule(minLength) {
        return (v) => (v && v.length >= minLength) || `Mínimo de ${minLength} caracteres`;
      }
    },

    computed: {
      permitirConsultar() {
        return (this.nomeUnidadeEnsino.length >= 4)
            || (this.municipio > 0)
            || (this.unidadeRegional > 0)
            || (this.cadastroEscolar.length >= 4)
            || (this.cnpj.length >= 4);
      }
    },
  };
</script>
