<template>
  <p style="display: none"></p>
</template>

<script>
import { EventBus } from "./EventBus.js";

export class ItemDeMenu {
  constructor(options = { menuTitle: "", URLendereco: "", itensDoSubMenu: [] }) {
    console.log(options);
    this.menuTitle =
      options.menuTitle ||
      options.title ||
      options.Titulo ||
      options.titulo ||
      options.nomeExibicao;
    this.URLendereco =
      options.endereco ||
      options.URLendereco ||
      options.IndicadorSecao ||
      options.indicadorSecao ||
      "#";
    this.ativo = options.ativo || options.Ativo;
    this.itensDoSubMenu = options.itensDoSubMenu || [];
    this.tipo = options.tipo || options.type;

    this.itensDoSubMenu?.map((item) => new ItemDeMenu(item));

    this.isValid = function () {
      return !!this.nomeExibicao;
    };

    this.stringify = function () {
      return JSON.stringify({ ...this });
    };

    this.toApi = function () {
      return {
        NomeExibicao: this.menuTitle,
        Endereco: this.URLendereco,
        ItensDoSubMenu: this.itensDoSubMenu.map((item) => new ItemDeMenu(item).toApi()),
        Ativo: this.ativo,
      };
    };

    this.isValidMenuItem = function () {
      return true;
    };
  }
}

export class Menu {
  constructor(
    options = {
      nomeExibicao: "Menu",
      itensDeMenu: [],
      IndicadorSecao: "",
    }
  ) {
    console.log(options);
    Object.defineProperty(this, "type", { get: () => "menu" });

    this.nomeExibicao = options.nomeExibicao || options.title || options.titulo;
    this.itensDeMenu =
      options.itensDeMenu || options.submenus || options.menus || options.Menus;
    this.IndicadorSecao = options.IndicadorSecao || "#";
    this.Ativo = options.ativo || options.Ativo;
    this.itensDeMenu?.forEach((item, index) => {
      Object.defineProperty(item, "IndicadorSecao", {
        get: () => `#secao-${index + 1}`,
      });
    });

    this.itensDeMenu?.map((item) => new ItemDeMenu(item));

    console.log(this);
    Object.defineProperty(this, "IndicadorSecao", {
      get: () => `#secao-${this.itensDeMenu.length + 12}`,
    });

    this.isValid = function () {
      return !!this.nomeExibicao;
    };

    this.stringify = function () {
      return JSON.stringify({ ...this, type: "menu" });
    };

    this.toApi = function () {
      return {
        nomeExibicao: this.nomeExibicao,
        Menus: this.itensDeMenu.map((item) => new ItemDeMenu(item).toApi()),
        Tipo: "menu",
        IndicadorSecao: this.IndicadorSecao,
        Endereco: this.IndicadorSecao,
      };
    };

    if (!this.isValid()) {
      // throw new Error("Seção de Menu inválida.");
    }
  }
}

export default {
  name: "LandingPageMenu",
  props: {
    preview: {
      type: Boolean,
      default: false,
    },
    showTopBarColor: {
      type: Boolean,
      default: false,
    },
    app: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.listenContentUpdate();
    this.$forceUpdate();
  },
  methods: {
    listenContentUpdate() {
      EventBus.$on("content-update", (newContent) => {
        this.content = newContent;
        this.$forceUpdate();
        EventBus.$emit("content-updated", this.content);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$negativeTopBarHeight: -64px;
.topBar {
  transition: all 0.75s;
}

.topBar--visible {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}

.page-container {
  .btn-logo-img {
    max-height: 36px;
  }

  &:not(.preview) {
    .cover {
      margin-top: $negativeTopBarHeight;
    }
  }
}

.white--text {
  color: white !important;
}
</style>
