<template>
  <v-container fluid class="page-container pa-0" :class="{ preview: preview }">
    <v-app-bar
      :fixed="!preview"
      flat
      :dark="!preview"
      color="transparent"
      class="topbar menu"
      :class="{
        'topbar--visible': showTopBarColor,
        'elevation-2': showTopBarColor,
      }"
      v-scroll="onScroll"
    >
      <v-btn plain @click="$vuetify.goTo(0, { duration: 1000, offset: 15 })">
        <img class="btn-logo-img" alt="Brasão de Pernambuco" src="/img/logo-SEE-branca-1-2.png" />
      </v-btn>

      <v-app-bar-title class="d-sm-none font-weight-bold">{{
        app.alias
      }}</v-app-bar-title>

      <v-spacer></v-spacer>

      <template v-for="section in content.sections">
        <template v-for="(subLink, i) in section.itensDeMenu">
          <v-btn
            v-if="
              subLink.Ativo ||
              (subLink.ativo && subLink.itensDoSubMenu && (!subLink.itensDoSubMenu.length || !subLink.itensDoSubMenu.filter(link => link.ativo).length))
            "
            @click="handleLink(subLink)"
            plain
            :key="i"
          >
            {{ subLink.nomeExibicao || subLink.menuTitle }}
          </v-btn>
          <v-menu :key="i" open-on-hover v-else transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="subLink.Ativo || subLink.ativo"
                v-bind="attrs"
                v-on="on"
                :preview="preview"
                plain
              >
                {{ subLink.nomeExibicao || subLink.menuTitle }}
              </v-btn>
            </template>
            <v-list class="mt-10" :dark="!preview" :class="{
              'topbar--visible': showTopBarColor,
              'elevation-2': showTopBarColor,
            }" color="transparent">
              <template
                v-for="(link, j) in subLink.itensDoSubMenu.filter((link) => link.ativo)"
              >
                <v-list-item :dark="!preview" :key="j">
                  <v-list-item-content :dark="!preview" v-if="link.Ativo || link.ativo">
                    <v-btn
                      :dark="!preview"
                      plain
                      v-if="link.ativo"
                      @click="handleLink(link)"
                      >{{ link.nomeExibicao || link.menuTitle }}</v-btn
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>
      </template>
      <v-spacer></v-spacer>
    </v-app-bar>

    <template v-for="section in content.sections">
      <component
        v-if="sectionComponent(section)"
        :is="sectionComponent(section)"
        :id="section.IndicadorSecao"
        :key="section.IndicadorSecao"
        :preview="preview"
        :section="section"
      ></component>
    </template>

    <lp-social :preview="preview"></lp-social>

    <lp-footer :preview="preview"></lp-footer>
  </v-container>
</template>

<script>
import { Cover } from "components/LandingPage/LandingPageCover";
import { Section } from "components/LandingPage/LandingPageSection";
import { Faq, FaqItem } from "components/LandingPage/LandingPageFaq";
import { Menu } from "components/LandingPage/LandingPageMenu";
import { ConsultarCredenciamento } from "components/LandingPage/LandingPageConsultarCredenciamento"

import configurations from "commons/configurations";
import pageLoadingMixin from "mixins/page-loading";
import notificationMixin from "mixins/notification";

import LpCover from "components/LandingPage/LandingPageCover";
import LpSection from "components/LandingPage/LandingPageSection";
import LpFaq from "components/LandingPage/LandingPageFaq";
import LpSocial from "components/LandingPage/LandingPageSocial";
import LpFooter from "components/LandingPage/LandingPageFooter";
import LpMenu from "components/LandingPage/LandingPageMenu";
import LpConsultarCredenciamento from "components/LandingPage/LandingPageConsultarCredenciamento"

export default {
  name: "LandingPage",
  components: {
    LpCover,
    LpSection,
    LpFaq,
    LpSocial,
    LpFooter,
    LpMenu,
   LpConsultarCredenciamento
  },
  mixins: [pageLoadingMixin, notificationMixin],
  props: {
    preview: {
      type: Boolean,
      required: false,
    },
    previewData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      app: {
        name: configurations.app.name,
        alias: configurations.app.alias,
      },
      content: {
        sections: [],
      },
      showTopBarColor: false,
      location: "end",
    };
  },
  mounted() {
    if (!this.preview) {
      this.loadContent().then(() => {
        this.hidePageLoading();

        const faqList = this.content.sections.filter((section) => section.type === "faq");

        if (Array.isArray(faqList) && faqList.length > 0) {
          this.fetchFaqItems().then((items) => {
            // Recria as seções
            const sections = this.content.sections.map((section) => {
              // recria as seções de FAQ com os itens
              if (section.type === "faq") {
                return new Faq({
                  menuTitle: section.menuTitle,
                  title: section.title,
                  items: items,
                  IndicadorSecao: section.IndicadorSecao,
                }); // novo objeto para provocar reatividade
              }

              return section;
            });

            // Atualiza a lista de seções com as FAQ atualizadas
            this.content.sections = sections;
          });
        }
      });
    } else {
      this.app = this.previewData.app;
      this.content.sections = this.previewData.sections;
      this.showTopBarColor = this.previewData.showTopBarColor;
    }
  },
  methods: {
    sectionComponent(section) {
      const components = {
        cover: "LpCover",
        faq: "LpFaq",
        regular: "LpSection",
      consultarCredenciamento: "LpConsultarCredenciamento",
      };

      if (section) {
        return components[section.type];
      }

      return undefined;
    },
    loadContent() {
      return this.$http()
        .get(configurations.api.home.sections)
        .then(({ data }) => {
          data.secoes
            .map((section) => {
              switch (section.tipo) {
                case "capa":
                  return new Cover(section);
                case "faq":
                  return new Faq(section);
                case "menu":
                  return new Menu(section);
                case "consultarCredenciamento":
                  return new ConsultarCredenciamento(section);
                default:
                  return new Section(section);
              }
            })
            .forEach((section) => {
              this.content.sections.push(section);
            });
        });
    },
    async fetchFaqItems() {
      return this.$http()
        .get(configurations.api.home.faq)
        .then(({ data }) => {
          return data.faq.map((item) => {
            return new FaqItem({
              id: item.id,
              title: item.pergunta,
              description: item.resposta,
              tokens: item.palavrasChave,
            });
          });
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.notifyWarning(response.data.mensagem);
          } else {
            this.notifyWarning(
              "Não conseguimos consultar as perguntas e respostas do FAQ."
            );
          }
        });
    },
    moveSection(section) {
      console.log(section);
      const element = document.getElementById(
        `${section.endereco || section.URLendereco || section.IndicadorSecao}`
      );
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    onScroll() {
      const offset = 150;

      if (this.preview) {
        return;
      }

      if (window.pageYOffset > offset) {
        this.showTopBarColor = true;
      } else {
        this.showTopBarColor = false;
      }
    },
    handleMoveTo(item) {
      console.log(item);
      if (!item.endereco?.includes("#")) {
        window.open("https://" + item.endereco, "_blank");
      } else if (!item.URLEndereco?.includes("#")) {
        window.open("https://" + item.URLEndereco, "_blank");
      }
    },
    handleLink(subItem) {
      if (
        subItem.URLendereco &&
        typeof subItem.URLendereco == "string" &&
        subItem.URLendereco.includes("http")
      ) {
        window.open(subItem.URLendereco, "_blank");
      } else if (
        subItem.endereco &&
        typeof subItem.endereco == "string" &&
        subItem.endereco.includes("http")
      ) {
        window.open(subItem.endereco, "_blank");
      } else if (
        subItem.endereco &&
        typeof subItem.endereco == "string" &&
        subItem.endereco.includes("#")
      ) {
        this.moveSection(subItem);
      } else if (
        subItem.URLendereco &&
        typeof subItem.URLendereco == "string" &&
        subItem.URLendereco.includes("#")
      ) {
        this.moveSection(subItem);
      } else {
        this.notifyWarning("Não foi possível acessar o link.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$negativeTopbarHeight: -64px;

.topbar {
  transition: all 0.75s;
}

.topbar--visible {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}

.page-container {
  .btn-logo-img {
    max-height: 36px;
  }

  &:not(.preview) {
    .cover {
      margin-top: $negativeTopbarHeight;
    }
  }
}

button .no-border {
  border: none !important;
}
</style>
